import { h, Component } from 'preact'
import { css } from 'emotion'

const className = css`
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;

  a {
    display: block;
  }

  .devices {
    display: none;
  }

  .iphone {
    width: 200px;
  }

  .android {
    width: 200px;
  }

  .ipad {
    width: 400px;
  }

  .macbook {
    width: 600px;
  }

  .client {
    margin: 2em 0 2em 0;

    &:before {
      content: attr( data-client );
      font-size: 1.5rem;
      font-weight: 400;
      text-transform: uppercase;
    }

    a {
      background-color: rgba(41,58,77, .05);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      border: 1px solid rgba(41,58,77, .25);
      display: block;
      height: 20rem;
      margin: 1em auto 1em auto;

      &:first-child {
        margin-top: 0;
      }

      img {
        display: none;
        margin-left: auto;
        margin-right: auto;
        width: 200px;
      }
    }

    @media (max-width: 999px) {
      a {
        img {
          display: none;
        }
      }
    }

    @media (min-width: 750px) {
      display: flex;
      margin: 1rem -.5rem 1rem -.5rem;
      position: relative;

      &:before {
        position: absolute;
        transform: rotate( 270deg );
        left: -.9rem;
        bottom: -.9rem;
        font-size: 1rem;
        transform-origin: 0 0;
        color: rgba(41,58,77, .5);
      }

      a {
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
        height: 12rem;
        margin: 0 .5rem 0 .5rem;
        width: calc( 25% - 1rem );
      }
    }
  }

  @media (min-width: 750px) {

    .content {
    }

    .gallery {
    }
  }
`

export default class Portfolio extends Component {
  constructor ( props ) {
    super( props )
    this.state = {
      clients: [
        {
          slug: 'bobsfurniture',
          name: 'Bob\'s Furniture'
        },
        {
          slug: 'catalina',
          name: 'Catalina'
        },
        {
          slug: 'crafteecottage',
          name: 'Craftee Cottage'
        },
        {
          slug: 'digitalbungalow',
          name: 'Digital Bungalow'
        },
        {
          slug: 'fiftydegreesnorth',
          name: 'Fifty Degrees North'
        },
        {
          slug: 'hartfordartschool',
          name: 'Hartford Art School'
        },
        {
          slug: 'hologic',
          name: 'Hologic'
        },
        {
          slug: 'mindpeers',
          name: 'Mindpeers'
        },
        {
          slug: 'mylvad',
          name: 'MyLVAD'
        },
        {
          slug: 'ncaa',
          name: 'NCAA'
        },
        {
          slug: 'scad',
          name: 'SCAD'
        },
        {
          slug: 'showcasecinemas',
          name: 'Showcase Cinemas'
        },
        {
          slug: 'stockrover',
          name: 'Stockrover'
        },
        {
          slug: 'stroud',
          name: 'Stroud'
        },
        {
          slug: 'visnickandcaulfield',
          name: 'Visnick & Caulfield'
        }
      ]
    }
  }
  componentDidMount () {
  }
  render ( props, state ) {
    return (
      <div class={className}>
        <div class='content'>
          <h2>Portfolio</h2>
          <p>
            This is a small sampling of the work that I've done over the past few years.  Much of the work I'm contracted for is covered under NDAs, but I'm happy to speak more generally about the problems I've been presented with by clients and the solutions we've implemented to meet those needs.
          </p>
        </div>
        <div class='devices'>
          <img class='android' src='/media/images/pixel.png' />
          <img class='iphone' src='/media/images/iphone.png' />
          <img class='ipad' src='/media/images/ipad.png' />
          <img class='macbook' src='/media/images/macbook.png' />
        </div>
        <div class='gallery'>
          {
            this.state.clients.map( client => {
              return (
                <div class='client' data-client={client.name}>
                  {
                    [ 1, 2, 3, 4 ].map( index => {
                      return <a href={'/media/images/' + client.slug + '/' + index + '.jpg'} style={'background-image:url(' + '/media/images/' + client.slug + '/t/' + index + '.png);'}>
                        <img src={'/media/images/' + client.slug + '/t/' + index + '.png'} />
                      </a>
                    } )
                  }
                </div>
              )
            } )
          }
        </div>
      </div>
    )
  }
}
