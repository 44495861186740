import { h, Component } from 'preact'
import { css } from 'emotion'
import md5 from 'md5'

const className = css`
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;

  img {
    border-radius: 50%;
    width: 200px;
    margin-left: auto;
    display: block;
    margin-right: auto;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  h1 {
    font-weight: 400;
    font-size: 6rem;
      font-family: 'Reenie Beanie';

    span {
      text-transform: uppercase;
    }
  }

  @media (min-width: 1000px) {
    margin-top: 25vh;    
  }
`

export default class Profile extends Component {
  constructor ( props ) {
    super( props )
    this.state = {
      gravatar: null
    }
  }
  fetchGravatar () {
    let gravatar = `https://gravatar.com/avatar/${md5( 'matt@substructu.re' )}?s=200`

    this.setState( {
      gravatar: gravatar
    } )
  }
  componentDidMount () {
    this.fetchGravatar()
  }
  render ( props, state ) {
    return (
      <div className={className}>
        <svg class='defs-only' style='display: none;'>
          <filter id='monochrome' color-interpolation-filters='sRGB'
            x='0' y='0' height='100%' width='100%'>
            <feColorMatrix type='matrix'
              values='0.71 0 0 0  0
        0.88 0 0 0  0
        1.07 0 0 0  0
        0  0 0 1  0' />
          </filter>
        </svg>
        <img src={state.gravatar} />
        <div>
          <h1>Hi, I'm <span>Matt</span>.</h1>
          <p>
            I'm a consulting developer originally from New York, but currently based out of <strong>Melbourne</strong>.  I'm a fan of non-complex solutions for everyday business problems.
          </p>
          <p>
            I've worked all over the stack, but currently specialize in the production of <strong>static sites</strong>, hooked up to a <strong>cloud-based CMS</strong>, offloading any additional functionality to <strong>functional micro-services</strong>. I like working with people smarter than me.
          </p>
          <p>
          My ideal engagement would start by working with a client who has specific business goals in mind. We would collaborate on a technical solution which can meet these business goals during a <a href='http://www.gv.com/sprint/'>product design sprint</a>, produce a simple spec that all stakeholders could agree on, and then we'd <a href='https://medium.com/cto-school/ditching-scrum-for-kanban-the-best-decision-we-ve-made-as-a-team-cd1167014a6f'>deliver the product in iterations</a> during weekly review sessions.
          </p>
          <p>If you'd like to setup some time to discuss an engagement, or if you just wanna catch up, you can find me over at <a href='https://www.linkedin.com/in/matthew-white-a4457646/' target='_blank' rel='noopener noreferrer'>LinkedIn</a>, and occasionally over at <a href='https://twitter.com/kaishir0' target='_blank' rel='noopener noreferrer'>Twitter</a>, and far too frequently over at <a href='https://news.ycombinator.com/user?id=kaishiro' target='_blank' rel='noopener noreferrer'>Hacker News</a>. And of course, there's always <a href='mailto:matt@substructu.re'>email</a>.</p>
        </div>
      </div>
    )
  }
}
