import 'babel-polyfill'
import {h, Component} from 'preact'
import Experience from './Experience'
import Profile from './Profile'
import Portfolio from './Portfolio'
import { injectGlobal } from 'emotion'

injectGlobal`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html {
    font-size: 10px;
    font-family: 'Source Sans Pro', sans-serif;
    color: rgb(41,58,77);
    background-color: rgb(239,234,231);
  }
  h2 {
    font-size: 4rem;
    font-weight: 400;
    margin-top: 4rem;
    text-transform: uppercase;
    font-family: 'Reenie Beanie';
  }
  h3 {
    font-size: 2rem;
    font-weight: 400;
    margin-top: 2rem;
  }
  main {
  }
  p {
    font-size: 1.75rem;
    font-weight: 300;
    margin: 1em 0 1em 0;
    line-height: 1.6;

    a {
      color: #EF8354;
      text-decoration: none;
      position: relative;

      &:hover {

        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 1px;
          width: 100%;
          content: '';
          background: #EF8354;
        }
      }
    }
  }
  strong {
    font-weight: 400;
  }
`

export default class App extends Component {
  render () {
    return (
      <main>
        <Profile />
        <Experience />
        <Portfolio />
      </main>
    )
  }
}
