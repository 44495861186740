import { h, Component } from 'preact'
import { css } from 'emotion'

const className = css`
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
`

export default class Experience extends Component {
  constructor ( props ) {
    super( props )
    this.state = {
    }
  }
  componentDidMount () {
  }
  render ( props, state ) {
    return (
      <div className={className}>
        <div>
          <h2>Backend</h2>
          <p>
            Early on in my career I started out in <strong>.NET</strong>, but while there is some historical knowledge there I would no longer consider it a marketable skill of mine.  After that I moved to <strong>PHP</strong> full-time for several years, and then as I became more involved with static site generators made the jump to <strong>Ruby</strong>.  There isn't much call for it anymore, but I enjoy working with <strong>SQL</strong> and it's variants.  In the past few years I've again migrated to having most of my "back-end" code being written in <strong>Javascript</strong> - originally via <strong>Iron Workers</strong> and <strong>Amazon Lambda</strong>, but more recently <strong>Google Cloud Functions</strong>.
          </p>
          <h2>Frontend</h2>
          <p>
            I have a strong working grasp of CSS, having used different flavors of <strong>LESS</strong>, <strong>Sass</strong>, and <strong>Stylus</strong> depending on project requirements.  More recently, I've dropped most larger CSS frameworks for <strong>PostCSS</strong>, with <strong>cssnext</strong> and a few other mainline plugins.
          </p>
          <p>
            I started off writing mostly <strong>jQuery</strong>, back when there was still a decision to be made between <a href='http://www.jqueryvsmootools.com/'>that and MooTools</a>. This held through most of my PHP days but I've since dropped it in favor of <a href='http://vanilla-js.com/'>Vanilla JS</a>.  I thought I would regret it - I haven't.  I typically roll in <strong>Lodash</strong>, because <a href='https://blog.codinghorror.com/how-to-be-lazy-dumb-and-successful/'>lazy code is good code</a>.  Everything is transpiled via <strong>Babel</strong>, bundled via <strong>Rollup</strong>, and polyfilled via <strong>Polyfill.io</strong>.  For SPAs, I used to pull in <strong>Mithril.js</strong>, but recently made the switch to <strong>Preact</strong>.
          </p>
          <h2>Utilities</h2>
          <p>
          I love <strong>git</strong>.  I love it.  I struggle to imagine a time before using it.  The reality is that we still had a VCS, but it was SVN, and it was running on bare metal sitting in a 90 degree closet.  It's terrifying to think of now.
          </p>
          <p>
          I've tried dozens of IDEs - I still use <strong>vim</strong>. <a href='https://github.com/tpope'>Tim Pope</a> is a genius and while I try to keep a slim .vimrc, I can't live without <strong>surround</strong> and <strong>fugitive</strong>.  At this point I can't imagine using something without vim bindings - it's probably <a href='https://medium.com/@ae/the-curse-of-vim-a72494ca1236'>a bit of a curse</a>.
          </p>
          <p>
          I'm a huge proponent of three things: <strong>static site generators</strong>, <strong>cloud-based CMSs</strong>, and <strong>cloud-based functional microservices</strong>.  This is a really powerful combination that allows you to host a site for pennies anywhere in the world, because it's back to just being "files on a box".
          </p>
          <p>
          I've played with dozens of SSGs, but currently my go-to solutions are either <strong>Brunch</strong> for basic projects or a custom SSG for anything requiring CMS integration (mainly for flow-through types).
          </p>
          <p>
          For CMSs, I'm a fan of <strong>Contentful</strong> for their product stability and support.  Their feature rollout schedule has weakened with all of that series B money though, and <strong>Dato</strong> is releasing features like their life depends on it.  It's fun to watch.
          </p>
          <p>
          For functional microservices, I like <strong>Google Cloud Functions</strong>.  It's a solid product, it's not going anywhere, and there are a plethora of integration points with other Google Cloud services.  It also gets you first-party integration with <strong>Firebase</strong> and <strong>Firestore</strong>, which still has no real turnkey competitor in the space - not even over at <strong>AWS</strong>.
          </p>
          <h2>Things I Read</h2>
          <p>
            I read <a href='https://news.ycombinator.com/news' target='_blank' rel='noopener noreferrer'>Hacker News</a>, a lot.  I read the comments before the articles.  There is a great community there, and it hasn't yet fallen apart under it's own weight.
          </p>
          <p>
            I read <a href='https://robots.thoughtbot.com/' target='_blank' rel='noopener noreferrer'>Giant Robots Smashing Into Other Giant Robots</a>. I believe that Thoughtbot is a company that should be emulated in a lot of ways.  I also believe it's one of the greatest titles for a blog I've ever seen.
          </p>
          <p>
            I read <a href='https://theoutline.com/'>The Outline</a>.  I think they have first rate design and it's one of the only online publishers to figure out what unobtrusive advertising should feel like.
          </p>
          <p>
            I read <a href='https://til.hashrocket.com/'>Today I Learned</a>, from Hashrocket. If I ever get around to starting a blog one day, I would steal this idea. So much of development is patterns, patterns, patterns.  I like the idea of a living archive of found solutions.
          </p>
          <p>
            I have a blind aunt, and in the past she's made me aware of how inaccessible the web can be to people with disabilities.  Because of this, I try to keep up with articles over at <a href=''>WebAIM</a>. <a href='https://webaim.org/blog/user-agent-string-history/'>This</a> is still one of the funniest things I've ever read.
          </p>
          <h2>Things I Miss</h2>
          <p>
          I miss <strong>database normalization</strong>. Lately I've been able to do a bit of SQL again while working on some custom dataset filtering of records stored up in Google Cloud SQL, exposed via an API stoodup in Google Cloud Functions.  I probably consider it a win that I rarely even consider database structure anymore, but I still miss it.
          </p>
        </div>
      </div>
    )
  }
}
